import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const HeroOne = () => {

    const slides = () => {
        const $ = window.$;
        let myVideo = document.getElementById("background-video");

        myVideo.play()
        myVideo.loop = true;
    }

    useEffect(() => {
        slides()
    }, []);

    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
            <section className="slider-one-sec style3 position-relative">
                <video id="background-video" className='w-100' muted playsInline autoPlay loop>
                    <source src={publicUrl + 'assets/videos/muted-bg.mp4'} type="video/mp4" />
                    <source src={publicUrl + 'assets/videos/muted-bg.mp4'} type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </section>
        </>
    )
}

export default HeroOne