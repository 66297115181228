import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import HeroOne from "../components/hero/HeroOne";
import AboutFour from '../components/about/AboutFour';
import ProjectThree from "../components/project/ProjectThree";
import ServiceThree from "../components/services/ServiceThree";
import FeatureFour from "../components/features/FeatureFour";
import WorkPerformanceOne from "../components/work-performance/WorkPerformanceOne";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";

const HomeDefault = () => {
    return (
        <>
            <HeaderOne />
            <HeroOne />
            <ServiceThree />
            <ProjectThree />
            <AboutFour />
            <FeatureFour />
            <WorkPerformanceOne />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default HomeDefault;